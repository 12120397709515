import { useContext, useEffect, useState } from 'react';
import { Col, Row, Form, Button, FloatingLabel, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProfilePic from './elements/ProfilePic';
import Confirm from './elements/Confirm';
import FeedbackToast from './elements/FeedbackToast';
import ResetPasswordModal from './auth/PasswordResetModal';
import useFeedback from './elements/customHooks/useFeedback';
import api, { endpoints } from '../utils/api';
import DisplayErrors from './elements/DisplayErrors';
import Layout from './Layout';

import { User } from '../models/auth.models';
import AuthenticationContext from "./auth/AuthenticationContext";

export default function SettingsPage() {
    const navigate = useNavigate();
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { done } = useFeedback();
    const [userData, setUserData] = useState<User | null | undefined>({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
    });

    const {
        setIsLoggedIn,
        setUser,
        meRef,
    } = useContext(AuthenticationContext);

    const [updatedUserData, setUpdatedUserData] = useState<User | null | undefined>({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
    });

    const [isUpdateUser, setUpdateUser] = useState(false);

    useEffect(() => {
        !isUpdateUser &&
            (async () => {
                try {
                    const response = await api.get(`${endpoints.accounts}/user`);
                    if (response.status === 200) {
                        setUpdatedUserData(response.data);
                        setUserData(response.data);
                        setUpdateUser(true);
                    }
                } catch (error) {
                    setErrors([error.message]);
                }
            })();
    }, [isUpdateUser]);

    const saveChanges = async () => {
        try {
            const response = await api.put(`${endpoints.accounts}/user`, JSON.stringify(userData), {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                done('Changes saved', 'success', 3000);
                setUpdateUser(false);
            }
        } catch (error) {
            setErrors([error.message]);
        }
    };

    const deleteAccount = async () => {
        try {
            await api.delete(`${endpoints.accounts}/user`);
            // Clear user context and log out
            setIsLoggedIn(false);
            setUser(null);
            meRef.current = ""; // Clear the email reference

            // Navigate to the login page or homepage
            navigate('/');
            done('Account deleted', 'success', 3000);
        } catch (error) {
            console.error('This one is not dieing O.O: ', error);
        }
    };

    const handleModalClose = () => setShowPasswordChangeModal(false);

    return (
        <Layout updatedUserData={updatedUserData}>
            <FeedbackToast />
            <div id="profile-main">
                <Form id="profile-container">
                    <Row id="profile-top-row">
                        <ProfilePic show={true} />
                        {/*<Button*/}
                        {/*    onClick={() => {*/}
                        {/*        navigate('/');*/}
                        {/*    }}*/}
                        {/*    id="go-home"*/}
                        {/*>*/}
                        {/*    Back Home*/}
                        {/*</Button>*/}
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="fn">
                            <FloatingLabel label="First Name">
                                <Form.Control
                                    type="text"
                                    value={userData?.firstName}
                                    onChange={(un) =>
                                        setUserData((prev: any) => ({
                                            ...prev,
                                            firstName: un.target.value,
                                        }))
                                    }
                                />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} controlId="ln">
                            <FloatingLabel label="Last Name">
                                <Form.Control
                                    type="text"
                                    value={userData?.lastName}
                                    onChange={(un) =>
                                        setUserData((prev: any) => ({
                                            ...prev,
                                            lastName: un.target.value,
                                        }))
                                    }
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="mail">
                        <FloatingLabel label="Email">
                            <Form.Control
                                type="email"
                                value={userData?.email}
                                onChange={(un) =>
                                    setUserData((prev: any) => ({
                                        ...prev,
                                        email: un.target.value,
                                    }))
                                }
                            />
                        </FloatingLabel>
                    </Form.Group>

                    <DisplayErrors errors={errors} />

                    <Row className="mb-3 justify-content-between">
                        <Col xs={6} className="mb-2 mb-md-0 pr-xs-1">
                            <Button
                                id="profile-save"
                                variant="primary"
                                onClick={() => saveChanges()}
                                className="w-100"
                            >
                                Save changes
                            </Button>
                        </Col>
                        <Col xs={6} className="mb-2 mb-md-0 pl-xs-1">
                            <Button
                                id="change-password-btn"
                                variant="secondary"
                                onClick={() => setShowPasswordChangeModal(true)}
                                className="w-100"
                            >
                                Change Password
                            </Button>
                        </Col>
                        <Col xs={12} className="mt-2 mt-md-0">
                            <Button
                                variant="danger"
                                onClick={() => Confirm(deleteAccount)}
                                className="w-100"
                            >
                                Delete Account
                            </Button>
                        </Col>
                    </Row>

                    <ResetPasswordModal
                        show={showPasswordChangeModal}
                        email={userData?.email}
                        handleClose={handleModalClose}
                        changePassword={true}
                    />
                </Form>
            </div>
        </Layout>
    );
}